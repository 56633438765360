import UsuarioModel from 'src/core/model/UsuarioModel'
import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'
import { LocalStorage, clone } from 'quasar'
import usuarioLoginStore from 'src/store/usuario-login/'
import RecuperarSenhaModel from 'src/model/usuario/RecuperarSenhaModel'
import axios from 'axios'

export default {
  name: 'Login',
  mixins: [NotificacaoMixin],
  data () {
    return {
      logado: LocalStorage.getItem('logado'),
      modelRecuperarSenha: new RecuperarSenhaModel(),
      modelRecuperarSenhaConfirmar: new RecuperarSenhaModel(true),

      salvando: false,
      requisitandoSenha: false,

      usuarioModel: null,
      email: '',
      senha: '',
      recuperarEmail: '',
      tab: 'login',
      codigo: '',
      lembrar: true,
      tipoPassword: true
    }
  },
  mounted () {
    if (!this.$store.hasModule('usuario-login')) this.$store.registerModule('usuario-login', usuarioLoginStore)
    this.usuarioModel = new UsuarioModel()

    if (this.logado) {
      this.notificacao('aviso', 'Você já esta logado', 3000)
      this.$router.push({ name: 'cliente.form' })
    }
  },
  methods: {
    efetuarLogin () {
      const aviso = this.notificacao('enviando', 'Entrando...')
      this.salvando = true

      this.usuarioModel.login2({ login: this.email, senha: this.senha, origem_whitelabel_id: 1 }).then((retorno) => {
        this.salvando = false
        aviso()

        if (retorno.erro === 0) {
          const notify = this.notificacao('salvo', 'Bem vindo!')
          this.$store.dispatch('usuario-login/setLogado', retorno.dados)
          LocalStorage.set('lembrarLogin', this.lembrar)
          axios.defaults.headers.common.Authorization = 'Bearer ' + retorno.dados.token

          setTimeout(() => notify(), 3000)
          // window.location.href = '/admin/'
          this.$router.push({ name: 'inicio-empresa' })
        } else {
          this.notificacao('erro', retorno.msg)
        }
      }).catch((erro) => {
        this.salvando = false
        aviso()
        console.error('erro', erro)
        this.notificacao('erro', 'Erro não identificado')
      })
    },

    validarSenha (senha = '') {
      if (!senha) senha = ''
      return senha.length < 1 || senha.length > 5
    },

    requisitarSenha () {
      this.requisitandoSenha = true
      const form = clone(this.modelRecuperarSenha.form)
      this.modelRecuperarSenha.salvar(form).then((response) => {
        this.requisitandoSenha = false
      }).catch(() => {
        this.requisitandoSenha = false
      })
    }
  }
}
